export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("apollo:error", (error) => {
    console.error(error);

    if (process.client) {
      const tokenCookie = useCookie("apollo:favorites.token");
      tokenCookie.value = null;
    }

    refreshNuxtData();

    // const router = useRouter();
    // router.push(error.url);

    // const route = useRoute();
    // reloadNuxtApp({
    //   force: true,
    //   path: route.path,
    // });

    // const route = useRoute();
    // const router = useRouter();
    // console.log("route", route)
    // router.push(route.path);

    // reloadNuxtApp({ force: true });
  });
});
