<template>
  <div
    :class="[
      'organism heading-transition pt-14',
      Boolean(organism.containerWidth) === false ? '' : 'container-full-width',
      Boolean(organism.arrow) === false ? 'pb-14' : 'arrow pb-[69px]',
    ]"
    :style="{
      backgroundColor: organism.backgroundColor || 'transparent',
      backgroundImage: organism.backgroundColor ? 'none' : 'linear-gradient(101deg, #1b3f68 0%, #265a94 97%)'
    }"
  >
    <h2
      class="text-3xl sm:text-[44px] font-cg text-white font-semibold text-center m-0 leading-none px-8"
    >
      {{ organism.title1 }}
    </h2>
  </div>
</template>

<style>
.heading-transition {
  

  &.arrow {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 25px),
      calc(50% - 25px) calc(100% - 25px),
      50% 100%,
      calc(50% + 25px) calc(100% - 25px),
      0 calc(100% - 25px)
    );
  }
}

@media screen and (max-width: 1023px) {
  .heading-transition {
    margin-bottom: 2rem;

    &.arrow {
      clip-path: none;
    }
  }
}
</style>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
